* {
    box-sizing: border-box;
}

body {
    display: flex;
    width: 100vw;
    height: 100vh;
    margin: 0;
    color: hsl(0, 0%, 5%);
    background-color: hsl(0, 0%, 95%);
    font-family: Nunito;
    line-height: 1.2;
}

body > #root {
    overflow: hidden;
    flex: auto;
    display: flex;
    flex-direction: column;
}
